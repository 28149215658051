/**
 * @description Keys for FPL types as defined in BYO Config JSON in Console
 * @enum {String}
 * @readonly
 */
export const FPL_TYPE_KEYS = {
  FPL_GLOBAL_CODES: 'fplGlobalCodes',
  FPL_IDS: 'fplIds',
};

/**
 * @description Keys for FPL types mapped to their ES product data equivalent key
 * @enum {Object}
 * @readonly
 */
const FPL_TYPE_KEY_TO_PRODUCT_DATA_KEY_MAP = new Map([
  [FPL_TYPE_KEYS.FPL_GLOBAL_CODES, 'featuredProductLocationCode'],
  [FPL_TYPE_KEYS.FPL_IDS, 'featuredProductLocationId'],
]);

/**
 * @function getConfigFplInfoByKey
 * @description Obtains all FPL type values by its key identifier within all BYO configs
 * in Console
 * @param {FPL_TYPE_KEYS} fplTypeKey Identifier of what FPL type is being searched for
 * @param {Object} byoConfigs The BYO configs retrieved from Console and deeply flattened
 * BYO config data parsed from the BYO configs
 * @returns {Array} A list of FPL values
 */
export function getConfigFplInfoByKey({ fplTypeKey, byoConfigs }) {
  return byoConfigs?.configs
    ? Object.values(byoConfigs.configs).map(
        ({ config }) => config[0].byoConfig[0][fplTypeKey][0]
      )
    : null;
}

/**
 * @function findByoConfigFplInProductData
 * @description Searches for a matching FPL type value from the BYO config data in console
 * within the associated products ES product data
 * @param {Array} byoConfigFpls The BYO config FPL values (Global Codes or IDs) for each
 * BYO config defined in Console
 * @param {Array} productFplValues A list of a products FPL values (Global Codes or
 * IDs) as defined in ES product data
 * @param {FPL_TYPE_KEYS} fplTypeKey Identifier of what FPL type is being searched for
 * @returns {Object|null} The FPL value found matching between BYO config and ES
 * product data
 */
function findByoConfigFplInProductData({
  byoConfigFpls,
  productFplValues,
  fplTypeKey,
}) {
  return byoConfigFpls.find((byoConfigFpl) => {
    return productFplValues.find((productFplValue) =>
      /**
       * because the `activeFeaturedProductLocationCodeList` and `activeFeaturedProductLocationIdList`
       * data shape differs between the returns from products/v2 (grid) and GQL (PDP) compared to the
       * cart, there has to be two parsing methods
       * 
       * for `productFplValues`, products/v2 and GQL return an array of objects with the FPL global
       * codes/IDs nested internally. example:
       * ``` "activeFeaturedProductLocationCodeList": [
          {
              "featuredProductLocationCode": "BYOBFPL"
          }
        ] ```
        
       * for `productFplValues`, echo cart returns a flat array of FPL global codes/IDs as strings.
       * example:
       * ``` "activeFeaturedProductLocationCodeList": ["BYOBFPL"], ```
       * 
       * due to complexities maintaining consistency across different backend systems, these data
       * shapes will remain as is for the time being and these two shapes will have to be supported
       */
      typeof productFplValue === 'object'
        ? productFplValue[
            FPL_TYPE_KEY_TO_PRODUCT_DATA_KEY_MAP.get(fplTypeKey)
          ] === byoConfigFpl
        : productFplValue === byoConfigFpl
    );
  });
}

/**
 * @function parseByoConfigsForMatchingConfig
 * @description Retrieves associated BYO config data for use given an FPL value
 * @param {Object} byoConfigs The BYO configs retrieved from Console and deeply flattened
 * BYO config data parsed from the BYO configs
 * @param {FPL_TYPE_KEYS} fplTypeKey Identifier of what FPL type is being searched for
 * @param {String} foundByoConfigFpl The FPL Global Code or ID value found matching
 * between BYO config and ES product data
 * @returns {Object|undefined} Associated BYO config data for the matching FPL value,
 * otherwise return `undefined` if no match exists
 */
function parseByoConfigsForMatchingConfig({
  byoConfigs,
  fplTypeKey,
  foundByoConfigFpl,
}) {
  return byoConfigs.flattenedConfigs.length > 0
    ? byoConfigs.flattenedConfigs.find(
        (config) => config.byoConfig[0][fplTypeKey][0] === foundByoConfigFpl
      )
    : undefined;
}

/**
 * @function getConfigForProductByFplType
 * @description Obtains all FPL type values by its key identifier within all BYO configs
 * in Console; uses that value to search for a matching FPL type value in the associated
 * products ES data; uses that match to retrieve the associated BYO config
 * data for use
 * @param {Object} byoConfigs The BYO configs retrieved from Console and deeply flattened
 * BYO config data parsed from the BYO configs
 * @param {FPL_TYPE_KEYS} fplTypeKey Identifier of what FPL type is being searched for
 * @param {Array} productFplValues A list of a products FPL values (Global Codes or
 * IDs) as defined in ES product data
 * @returns {Object|null} Associated BYO config data for a given product, or, null
 * if no match is found
 */
function getConfigForProductByFplType({
  byoConfigs,
  fplTypeKey,
  productFplValues,
}) {
  const byoConfigFpls = getConfigFplInfoByKey({
    byoConfigs,
    fplTypeKey,
  });

  const foundByoConfigFpl = findByoConfigFplInProductData({
    byoConfigFpls,
    productFplValues,
    fplTypeKey,
  });

  if (foundByoConfigFpl) {
    const { byoConfig, gridUrl, offerTypeKey, offerTypePlainText } =
      parseByoConfigsForMatchingConfig({
        byoConfigs,
        fplTypeKey,
        foundByoConfigFpl,
      });

    const byoProductId = Object.values(byoConfigs.configs).find((entry) => {
      return entry.config[0]?.offerTypeKey
        ? entry.config[0]?.offerTypeKey === offerTypeKey
        : null;
    }).byoProductId;

    return {
      byoProductId,
      byoConfig,
      gridUrl,
      offerTypeKey,
      offerTypePlainText,
      isByoQualifiedProduct: byoConfig && byoConfig.length > 1,
    };
  }

  return null;
}

/**
 * @function getConfigForIndividualProduct
 * @description Conditionally retrieves the BYO config for a given product dependent on the
 * presence of FPL Global Codes or FPL IDs. FPL Global Codes have priority over FPL IDs.
 * @param {Object} byoConfigs The BYO configs retrieved from Console and deeply flattened
 * BYO config data parsed from the BYO configs
 * @param {Array} productFplGlobalCodes A list of a products FPL Global Codes defined in ES
 * @param {Array} productFplIds A list of a products FPL IDs defined in ES
 * @returns {Object|null} BYO config data or null if there are no matching FPL Global Codes
 * or IDs in BYO configs
 */
function getConfigForIndividualProduct({
  byoConfigs,
  productFplGlobalCodes,
  productFplIds,
}) {
  let byoConfig = null;

  if (productFplGlobalCodes) {
    byoConfig = getConfigForProductByFplType({
      byoConfigs,
      fplTypeKey: FPL_TYPE_KEYS.FPL_GLOBAL_CODES,
      productFplValues: productFplGlobalCodes,
    });
  }

  if (productFplIds && !byoConfig) {
    byoConfig = getConfigForProductByFplType({
      byoConfigs,
      fplTypeKey: FPL_TYPE_KEYS.FPL_IDS,
      productFplValues: productFplIds,
    });
  }

  return byoConfig;
}

/**
 * @function getConfigForBundle
 * @description Given a BYO bundles `masterProductId`, retrieve the associated
 * BYO config from those defined in Console
 * @param {Number} masterProductId A BYO bundles master product ID
 * @param {Object} byoConfigs The BYO configs retrieved from Console and deeply flattened
 * BYO config data parsed from the BYO configs
 * @returns {Object|null} BYO config data for the current BYO bundle or `null`
 * if no matching BYO config is found for the current BYO bundle
 */
function getConfigForBundle({ masterProductId, byoConfigs }) {
  const config = Object.keys(byoConfigs.configs).includes(
    masterProductId.toString()
  )
    ? byoConfigs.configs[masterProductId]
    : null;

  if (config) {
    const {
      byoProductId,
      config: [{ byoConfig, gridUrl, offerTypeKey, offerTypePlainText }],
    } = config;

    return {
      byoProductId,
      byoConfig,
      gridUrl,
      offerTypeKey,
      offerTypePlainText,
      isByoQualifiedProduct: byoConfig && byoConfig.length > 1,
    };
  }

  return null;
}

/**
 * @function getConfigForProduct
 * @description Conditionally retrieves the BYO config for a given product dependent on the
 * presence of FPL Global Codes or FPL IDs. FPL Global Codes have priority over FPL IDs.
 * @param {Array} productFplGlobalCodes A list of a products FPL Global Codes defined in ES
 * @param {Array} productFplIds A list of a products FPL IDs defined in ES
 * @param {Number} masterProductId A BYO bundles master product ID
 * @param {Object} byoConfigs The BYO configs retrieved from Console and deeply flattened
 * BYO config data parsed from the BYO configs
 * @returns {Object|null} BYO config data or null if there are no matching FPL Global Codes
 * or IDs in BYO configs
 */
export function getConfigForProduct({
  productFplGlobalCodes,
  productFplIds,
  masterProductId,
  byoConfigs,
}) {
  // individual product
  if ((productFplGlobalCodes || productFplIds) && byoConfigs.configs) {
    return getConfigForIndividualProduct({
      byoConfigs,
      productFplGlobalCodes,
      productFplIds,
    });
  }

  // bundle/outfit/set
  if (
    !productFplGlobalCodes &&
    !productFplIds &&
    masterProductId &&
    byoConfigs.configs
  ) {
    return getConfigForBundle({
      masterProductId,
      byoConfigs,
    });
  }

  return null;
}
