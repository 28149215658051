import React from 'react';

import PropTypes from 'prop-types';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import styled from 'styled-components';

import { getMemberCreditPriceThreshold } from '~/shared/constants/productGrid';
import useByoConfigForProduct from '~/shared/utils/useByoConfigForProduct';
import { useMembership } from '~/techstyle-shared/react-accounts';
import { Tooltip } from '~/techstyle-shared/react-components';
import { FormattedMessage } from '~/techstyle-shared/react-intl';
import { useDomain } from '~/techstyle-shared/redux-core';

const TooltipAnchor = styled(Tooltip.Anchor)`
  width: 0;
  display: inline-block;
`;

const TooltipMessage = styled(Tooltip.Content)`
  background: ${({ theme }) => theme.colors.backgroundLight};
  max-width: 270px;
  padding: 16px 12px;
  border: 1px solid
    ${({ theme }) => theme.colors.memberCreditCalloutToolipBorder};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 5;
  border-radius: 8px;
  text-transform: none;
  font-weight: 400;
  font-size: 12px;
`;

const TooltipIcon = styled(HiOutlineInformationCircle)`
  width: 15px;
  height: 15px;
`;

const TooltipInfoIcon = styled.span`
  padding-left: 1px;
`;

const FreeTrialMemberCreditTooltip = () => {
  return (
    <Tooltip>
      <TooltipAnchor>
        <TooltipInfoIcon>
          <TooltipIcon />
        </TooltipInfoIcon>
      </TooltipAnchor>
      <TooltipMessage>
        <FormattedMessage
          id="site_product_page.free-trial-member-credit-tooltip"
          defaultMessage="NOT FINAL - One of the benefits of becoming a Full VIP is that each month when they are billed, they receive a Member Credit that can be redeemed for any item or outfit up to $100."
        />
      </TooltipMessage>
    </Tooltip>
  );
};

const PriceOfferOr = styled.span`
  color: ${({ theme }) => theme.colors.priceOfferOr};
`;

const MemberCreditCalloutCopy = styled.span`
  display: inline-block;
  text-transform: uppercase;

  ${({ theme }) => theme.memberCreditCallout.memberCreditCalloutCopy};
  ${({ wrapperStyles }) => wrapperStyles};
`;

const DynamicFormattedMessage = FormattedMessage;

function MemberCreditCallout({
  isTokenOnly = false,
  product,
  tokenValue,
  wrapperStyles = null,
}) {
  const { isByoQualifiedProduct, offerTypeKey, offerTypePlainText } =
    useByoConfigForProduct({
      productFplGlobalCodes: product?.activeFeaturedProductLocationCodeList,
      productFplIds: product?.activeFeaturedProductLocationIdList,
      masterProductId:
        product?.bundleItems?.length > 0 ? product.masterProductId : null,
    });

  const { inFreeTrial } = useMembership();
  const { tld } = useDomain();

  const memberCreditCopy = isByoQualifiedProduct ? (
    <DynamicFormattedMessage
      id={`byob.${offerTypeKey}_member_credit_callout`}
      defaultMessage={`${offerTypePlainText} Member Credit`}
      values={{ 1: offerTypePlainText }}
    />
  ) : (
    product.defaultUnitPrice >= getMemberCreditPriceThreshold(tld) && (
      <span data-autotag={`${product.productLabel}-membercredit1`}>
        {tokenValue === 1 ? (
          <FormattedMessage
            id="grid.outfit_credit"
            defaultMessage="{1} VIP Member Credit"
            values={{ 1: tokenValue }}
          />
        ) : (
          <FormattedMessage
            id="grid.outfit_credits"
            defaultMessage="{1} VIP Member Credits"
            values={{ 1: tokenValue }}
          />
        )}
        &nbsp;
        {inFreeTrial && <FreeTrialMemberCreditTooltip />}
      </span>
    )
  );

  return memberCreditCopy ? (
    <>
      {!isTokenOnly && (
        <>
          &nbsp;
          <PriceOfferOr>
            <FormattedMessage
              id="global_cta.outfit_credit_or"
              defaultMessage="or"
            />
          </PriceOfferOr>
          &nbsp;
        </>
      )}
      <MemberCreditCalloutCopy
        data-autotag="member_credit_callout"
        wrapperStyles={wrapperStyles}
      >
        {memberCreditCopy}
      </MemberCreditCalloutCopy>
    </>
  ) : null;
}

MemberCreditCallout.propTypes = {
  isTokenOnly: PropTypes.bool,
  product: PropTypes.object,
  tokenValue: PropTypes.number,
  wrapperStyles: PropTypes.array,
};

export default MemberCreditCallout;
