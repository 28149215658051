/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react';

import featureNames from '../constants/featureFlags';

import { getConfigForProduct } from './byoConfigHelpers';
import useByoConfigsIfEnabled from './useByoConfigsIfEnabled';
import useHasFeature from './useHasFeature';

const defaultState = {
  byoProductId: '',
  byoConfig: [],
  gridUrl: '',
  offerTypeKey: '',
  offerTypePlainText: '',
  isByoQualifiedProduct: false,
};

/**
 * @function useByoConfigForProduct
 * @description Returns the BYO config related data defined in Console for a given
 * product if the ES product data includes an active FPL Global Code or FPL ID (individual
 * products) or a master product ID (bundles) that matches a BYO bundles FPL Global Code, ID,
 * or BYO product ID as defined in the BYO config JSON in Console
 * @param {Array} productFplGlobalCodes A list of a products FPL Global Codes defined in ES
 * @param {Array} productFplIds A list of a products FPL IDs defined in ES
 * @param {Number} masterProductId A products master product ID
 * @returns {Object} The BYO config from Console for the associated product
 */
export default function useByoConfigForProduct({
  productFplGlobalCodes,
  productFplIds,
  masterProductId,
}) {
  const isByoEnabled = useHasFeature(featureNames.BUILD_YOUR_OWN_BUNDLE);
  const [config, setConfig] = useState(defaultState);

  const setByoConfig = useCallback(
    (byoConfigs) => {
      if (isByoEnabled && Object.keys(byoConfigs.configs).length > 0) {
        /**
         * returns a found BYO config for the given product. if none
         * found, `getConfigForProduct` returns null in which case
         * we should reset the state
         */
        const byoConfig = getConfigForProduct({
          productFplGlobalCodes,
          productFplIds,
          masterProductId,
          byoConfigs,
        });

        if (byoConfig) {
          setConfig(byoConfig);
        } else {
          setConfig(defaultState);
        }
      }
    },
    [
      /**
       * we don't care about other dependencies because we should only depend on updating if:
       * - BYO configs defined in Console change for some reason
       * - A related swatch is clicked, therefore updating the products FPL Global Codes or
       *  IDs (currently only supported on a PDP)
       */
      JSON.stringify(productFplGlobalCodes),
      JSON.stringify(productFplIds),
    ]
  );

  useByoConfigsIfEnabled(setByoConfig);

  return config;
}
