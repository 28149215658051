import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import {
  getByoConfigs,
  useProductActions,
} from '~/techstyle-shared/react-products';

import featureNames from '../constants/featureFlags';

import useHasFeature from './useHasFeature';

/**
 * @function useByoConfigs
 * @description Stores BYO configs in state if BYO is enabled
 * @param {Function} callback Callback function
 */
export default function useByoConfigsIfEnabled(callback) {
  const isByoEnabled = useHasFeature(featureNames.BUILD_YOUR_OWN_BUNDLE);
  const actions = useProductActions();
  const byoConfigs = useSelector(getByoConfigs);

  useEffect(() => {
    if (isByoEnabled) {
      if (!byoConfigs.networkStatus.isUpToDate) {
        const options = {
          includeFlattenedConfigs: true,
        };
        actions.loadByoConfigs({ options });
      }
      const configs = {
        configs: byoConfigs.configs,
        flattenedConfigs: byoConfigs.flattenedConfigs,
      };
      callback(configs);
    }
  }, [
    isByoEnabled,
    actions,
    byoConfigs.configs,
    byoConfigs.flattenedConfigs,
    byoConfigs.networkStatus.isUpToDate,
    callback,
  ]);
}
